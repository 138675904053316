import {Link} from 'gatsby';
import React, {useMemo} from 'react';
import './SegmentedControl.less';

interface SegmentedControlOption {
  name: string;
  label: string;
  destination: string;
}

interface SegmentedControlProps {
  options: SegmentedControlOption[];
  selectedOptionName: string;
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({options, selectedOptionName}) => {
  const optNodes = useMemo(() => {
    return options.map(opt => {
      const isCurrent = opt.name === selectedOptionName;
      const className = isCurrent ? 'Current' : undefined;
      const isExternal = isCurrent || opt.destination.indexOf('http') === 0;
      return isExternal ? (
        <a href={(!isCurrent && opt.destination) || ''} key={opt.name} className={className}>
          {opt.label}
        </a>
      ) : (
        <Link to={!isCurrent && opt.destination} key={opt.name} className={className}>
          {opt.label}
        </Link>
      );
    });
  }, [options, selectedOptionName]);

  return <div className="SegmentedControl">{optNodes}</div>;
};

export default SegmentedControl;
