import classNames from 'classnames';
import React from 'react';
import {BaseModuleProps} from '../include/Types';
import Module from './Module';
import './ProseModule.less';

const ProseModule: React.FC<BaseModuleProps> = ({className, ...otherProps}) => (
  <Module contentSize="Medium" className={classNames(className, 'ProseModule')} {...otherProps} />
);

export default ProseModule;
