import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../../components/Layout';
import ProseModule from '../../components/ProseModule';
import SegmentedControl from '../../components/SegmentedControl';
import SimpleHeaderModule from '../../components/SimpleHeaderModule';

export default () => (
  <Layout description="Guide to Miter's Zoom app for Zoom account admins">
    <SimpleHeaderModule title="Miter for Zoom: Docs">
      <SegmentedControl
        selectedOptionName="admins"
        options={[
          {name: 'users', label: 'Users', destination: '/zoom-docs'},
          {name: 'admins', label: 'Zoom Admins', destination: '/zoom-docs/admin-guide'},
        ]}
      />
    </SimpleHeaderModule>
    <ProseModule>
      <h2>What is Miter for Zoom?</h2>
      <p>
        <Link to="/" target="_blank">
          Miter
        </Link>{' '}
        is the <strong>better-meeting assistant:</strong> a software-as-a-service product, currently offered free of
        charge, that helps people run better meetings. It's available not only as a Zoom App, but also integrates with
        Google Calendar and is available as a <a href="https://app.miter.co">web app.</a>
      </p>
      <p>
        <a href="https://marketplace.zoom.us/apps/4gAucbSMSAi4qaaQPmXatA">Miter for Zoom</a> is a Zoom App, available
        through Zoom's App Marketplace. It allows Miter users to access Miter inside a Zoom meeting window, and provides
        tighter integration between Zoom and Miter.
      </p>
      <p>
        Miter for Zoom has been vetted by Zoom's team, including a security review. The Miter-Zoom integration provides
        Miter with a small amount of information about a Zoom user and her meetings (e.g., email address, name, user
        Zoom ID, Zoom Meeting ID, and meeting title), which we treat in accordance with our{' '}
        <Link to="/terms">terms of use</Link> and <Link to="/privacy">privacy policy</Link>.
      </p>

      <h2>Overview: Installation &amp; Approval</h2>
      <p>
        Zoom Apps are installed on a per-user basis--that is, a Zoom user can access Miter for Zoom via its App
        Marketplace link and install it in for use in her Zoom account.
      </p>
      <p>
        Optionally, <strong>Zoom account admins can require per-Zoom-App pre-approval</strong>, preventing users in
        their organization from installing a given Zoom App until they pre-approve its use.
      </p>
      <p>
        If your account is configured to require pre-approval, users who try to install Miter for Zoom will have the
        option to request your pre-approval for it.{' '}
        <strong>You may not receive a notification when a user requests pre-approval,</strong> but Zoom provides a list
        of pending approvals.
      </p>

      <h2>Configuring Pre-Approval</h2>
      <p>
        To enable or disable Zoom App pre-approvals for your organization's account,{' '}
        <a href="https://marketplace.zoom.us/user/permissions" target="_blank">
          <strong>visit the App Marketplace's Permissions page</strong>
        </a>
        :
      </p>
      <StaticImage
        src="../../../images/zoom-docs/preapproval-switch.png"
        alt="Permissions page showing pre-approval switch"
        className="Shadowed"
      />
      <ul>
        <li>
          <strong>If this switch is turned off</strong>, people in your organization will be able to install Zoom Apps
          without bothering you for pre-approval. You can always see which apps are in use on the
          <a href="https://marketplace.zoom.us/user/dashboard/installed-apps" target="_blank">
            Apps on Account
          </a>{' '}
          page.
        </li>
        <li>
          <strong>With the switch turned on,</strong> you will need to approve each Zoom App individually before anyone
          in your organization can use it. You can see pending requests on the{' '}
          <a href="https://marketplace.zoom.us/user/requests" target="_blank">
            App Requests page.
          </a>{' '}
          <strong>Note:</strong> While Zoom provides email-notification options for pre-approval requests, at the time
          of this writing those emails are often not delivered. That is,{' '}
          <strong>even with email notifications enabled you will not be proactively notified</strong> of new requests.
        </li>
      </ul>

      <h2>Pre-Approving Miter for Zoom</h2>
      <p>
        If you have required pre-approval for your organization via the option described above, you will need to
        pre-approve Miter for Zoom before anyone in your organization can use it.{' '}
        <strong>You only need to do this once.</strong>
      </p>
      <p>
        Simply{' '}
        <a href="https://marketplace.zoom.us/apps/4gAucbSMSAi4qaaQPmXatA" target="_blank">
          <strong>visit Miter for Zoom</strong>
        </a>{' '}
        in the Marketplace, where you'll see a switch to approve the app:
      </p>
      <StaticImage
        src="../../../images/zoom-docs/app-with-approval.png"
        alt="Pre-approval switch on the app's Marketplace page"
        className="Shadowed"
      />
      <p>
        Once you've turned this switch on, people in your organization (including you) will be able to install Miter for
        Zoom.
      </p>
      <p>
        Thanks for your interest in Miter! If you have any questions, please don't hesitate to{' '}
        <Link to="/contact">contact us</Link>.
      </p>
    </ProseModule>
  </Layout>
);
